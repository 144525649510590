import { styled } from "@mui/material/styles";

export const ColorlibStepIcon = ({ active, completed, iconUrl, className }) => {
    const ImageContainer = styled("div")(({ theme, ownerState }) => ({
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#0a0a2d",
      zIndex: 1,
      color: "#fff",
      border: "2px solid white",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      ...(ownerState.active && {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      }),
      ...(ownerState.completed && {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
      }),
    }));
  
    return (
      <div className="relative">
        <ImageContainer ownerState={{ completed, active }} className={className}>
          <img
            src={process.env.PUBLIC_URL + iconUrl}
            alt=""
            style={{ width: "100%", height: "80%", borderRadius: "50%" }}
          />
        </ImageContainer>
      </div>
    );
  };